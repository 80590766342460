<template>
  <v-container fluid>
    <v-row :id="block_id">
      <v-col
        v-click-outside="triggerOnFocus"
        cols="6"
      >
        <div
          class="statistics-box d-flex kdt-main-info flex-wrap"
          :class="{ 'blocked-box': blocked_visibility }"
        >
          <v-col cols="8">
            <label>
              1 Декларация
              <div class="d-flex">
                <v-text-field
                  value="КДТ"
                  outlined
                  hide-details="auto"
                  dense
                  disabled
                  background-color="grey lighten-2"
                  class="mr-1 p-0"
                />
                <v-autocomplete
                  ref="declaration_kind_code"
                  v-model="type_declaration.declaration_kind_code"
                  auto-select-first
                  outlined
                  hide-details="auto"
                  dense
                  background-color="white"
                  :items="['ИМ', 'ЭК']"
                  class="mr-1 p-0"
                  @change="kindChange"
                />
                <v-autocomplete
                  ref="customs_procedure"
                  v-model="type_declaration.customs_procedure"
                  auto-select-first
                  outlined
                  hide-details="auto"
                  dense
                  background-color="white"
                  :items="filteredProcedures"
                  item-text="text"
                  item-value="code"
                  class="mr-1 p-0"
                />
                <v-autocomplete
                  ref="previous_customs_procedure"
                  v-model="type_declaration.previous_customs_procedure"
                  auto-select-first
                  outlined
                  hide-details="auto"
                  dense
                  background-color="white"
                  :items="customsProcedures"
                  item-text="text"
                  item-value="code"
                  class="mr-1 p-0"
                />
              </div>
            </label>
          </v-col>

          <v-col cols="4">
            <label>
              7 Код особенности декл.
              <div class="d-flex">
                <v-autocomplete
                  ref="declaration_feature"
                  v-model="type_declaration.declaration_feature"
                  :items="declarationFeatures"
                  auto-select-first
                  outlined
                  hide-details="auto"
                  dense
                  background-color="white"
                  item-text="code"
                  item-value="code"
                  class="mr-1 p-0"
                  :filter="filterBySearchField"
                  @change="suggestRecalc"
                />
              </div>
            </label>
          </v-col>
          <v-col
            class="pt-0"
            cols="4"
          >
            <label>
              Дата ВДТ
              <custom-date-picker
                ref="declaration_feature_date"
                v-model="type_declaration.declaration_feature_date"
                :disabled="type_declaration.declaration_feature !== 'ВДТ'"
                :background-color="disabled ? 'grey lighten-2' : 'white'"
                :visited.sync="fields_been_visited"
                :has-changes.sync="hasChanges"
                @handleChange="suggestRecalc"
              />
            </label>
          </v-col>
          <v-col
            class="pt-0"
            cols="4"
          >
            <label>
              45а Код изменений
              <v-icon
                tabindex="-1"
                class="mx-0"
                small
                dense
                @click="showChangeCode = true"
              >
                mdi-tune
              </v-icon>
              <v-text-field
                ref="correction_reason_json"
                v-model="getCorrectionReason"
                background-color="white"
                outlined
                dense
                readonly
                hide-details="auto"
              />
            </label>
          </v-col>
          <v-col
            class="pt-0"
            cols="4"
          >
            <label>
              Номер КДТ
              <v-text-field
                ref="object_ordinal"
                v-model="type_declaration.object_ordinal"
                maxlength="2"
                background-color="white"
                outlined
                dense
                hide-details="auto"
              />
            </label>
          </v-col>
        </div>
      </v-col>

      <v-col
        v-click-outside="triggerOnFocus"
        cols="6"
      >
        <div class="statistics-box d-flex flex-wrap">
          <v-col cols="6">
            <label>
              А Регистрационный номер
              <field-menu>
                <v-list>
                  <v-list-item @click="disabled = !disabled">Ручное заполнение</v-list-item>
                  <v-list-item @click="fillWithCustomsData">Заполнить данными из таможни</v-list-item>
                  <v-list-item @click="clearRegNumA">Очистить регистрационный номер и сменить ID
                    документа</v-list-item>
                </v-list>
              </field-menu>
              <v-text-field
                ref="reg_num_a"
                v-model="registration_details.reg_num_a"
                v-mask="'#####/######/#######'"
                :background-color="disabled ? 'grey lighten-2' : 'white'"
                :disabled="disabled"
                outlined
                dense
                hide-details="auto"
              />
            </label>
          </v-col>
          <v-col cols="6">
            <label> Дата выпуска </label>
            <custom-date-picker
              ref="reg_date_b"
              v-model="registration_details.reg_date_b"
              :background-color="disabled ? 'grey lighten-2' : 'white'"
              :disabled="disabled"
              :visited.sync="fields_been_visited"
              :has-changes.sync="hasChanges"
            />
          </v-col>
          <v-col
            class="pt-0"
            cols="6"
          >
            <label>
              Рег. номер кор. док-та
              <v-text-field
                ref="correction_reg_num"
                v-model="type_declaration.correction_reg_num"
                v-mask="'#####/######/#######'"
                outlined
                hide-details="auto"
                dense
                class="mr-1 p-0"
                :background-color="'white'"
              />
            </label>
          </v-col>
          <v-col
            class="pt-0"
            cols="6"
          >
            <label>
              Дата ДТ
              <custom-date-picker
                ref="declaration_feature_date"
                v-model="type_declaration.dt_declaration_date"
                :background-color="'white'"
                :visited.sync="fields_been_visited"
                :has-changes.sync="hasChanges"
                disabled
              />
            </label>
          </v-col>
        </div>
      </v-col>
    </v-row>
    <recalculate-suggestion
      :show.sync="showSuggestion"
      :filled-declaration-feature-date="filledDeclarationFeatureDate"
    />
    <change-code
      :show.sync="showChangeCode"
      :correction-reason="type_declaration.correction_reason_json"
      :copy-in-other-wares.sync="copyInOtherWares"
      @setCorrectionReasonJson="setCorrectionReasonJson"
    />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import CustomDatePicker from "@/components/shared/custom-date-picker.vue";
import RecalculateSuggestion from "@/components/kdt/recalculate-suggestion.vue";
import ChangeCode from "@/components/kdt/сhange-code-modal.vue";
import FieldMenu from "@/components/documents/field-menu.vue";
import { eventBus } from "@/bus.js";
import blockVisibility from "@/mixins/block-visibility";
import copyFieldMixin from "@/mixins/copy-field.mixin";
import { type_declaration as onTypeDeclaration } from "@/events/statistics/control";
// import {highlightField} from "@/helpers/control";
import { filterBySearchFieldMixin } from "@/mixins/catalogs";

export default {
  components: {
    CustomDatePicker,
    RecalculateSuggestion,
    FieldMenu,
    ChangeCode,
  },
  mixins: [blockAutoUpdate, blockVisibility, filterBySearchFieldMixin, copyFieldMixin],
  data() {
    return {
      date: null,
      block_id: "kdt-main-info",
      disabled: true,
      type_declaration: {
        declaration_id: null,
        customs_procedure: "",
        declaration_kind_code: "",
        previous_customs_procedure: "",
        declaration_feature: "",
        declaration_feature_date: "",
        dt_declaration_date: null,
        object_ordinal: null,
        correction_reg_num: null,
        correction_reason_json: {
          basis_reason: null,
          customs_cost_reason: null,
          origin_country_reason: null,
          other_reason: null,
          payments_reason: null,
          quantity_and_weight_reason: null,
          stage_reason: null,
          tn_ved_reason: null,
        },
      },
      registration_details: {
        declaration_id: null,
        reg_num_a: "",
        reg_date_b: "",
      },
      showSuggestion: false,
      showChangeCode: false,
      copyInOtherWares: false, // for ChangeCode modal
    };
  },
  computed: {
    ...mapGetters({
      selected: "kdt/getSelected",
      selected_wares: "kdt/getSelectedWares",
      customsProcedures: "catalogs/getNsiCustomsProcedures",
      declarationFeatures: "catalogs/getNsiDeclarationFeatures",
    }),
    quantity() {
      return this.selected_wares?.length || 0;
    },
    additionalPages() {
      return this.quantity <= 1 ? 1 : Math.ceil((this.quantity - 1) / 3) + 1;
    },
    filteredProcedures() {
      if (this.type_declaration.declaration_kind_code === "ЭК") {
        return this.customsProcedures.filter((i) => i.direction === 0);
      } else {
        return this.customsProcedures.filter((i) => i.direction === 1);
      }
    },
    // есть ли дата ВДТ
    filledDeclarationFeatureDate() {
      return !!this.type_declaration?.declaration_feature_date;
    },
    // Объединяю инф-цию для поля 45а Код изменений
    getCorrectionReason() {
      let result = "";
      result +=
        this.type_declaration.correction_reason_json.basis_reason ?? "X";
      result +=
        this.type_declaration.correction_reason_json.stage_reason ?? "X";
      result +=
        this.type_declaration.correction_reason_json
          .quantity_and_weight_reason ?? "X";
      result +=
        this.type_declaration.correction_reason_json.origin_country_reason ??
        "X";
      result +=
        this.type_declaration.correction_reason_json.tn_ved_reason ?? "X";
      result +=
        this.type_declaration.correction_reason_json.customs_cost_reason ?? "X";
      result +=
        this.type_declaration.correction_reason_json.payments_reason ?? "X";
      result +=
        this.type_declaration.correction_reason_json.other_reason ?? "X";
      return result.split("").join("/");
    },
  },
  watch: {
    selected() {
      this.setFields();
    },
    "type_declaration.declaration_feature"(oldValue) {
      if (oldValue) this.type_declaration.declaration_feature_date = "";
    },
  },
  created() {
    onTypeDeclaration.subscribe(this.highlightField);
  },
  beforeDestroy() {
    onTypeDeclaration.unsubscribe();
  },
  methods: {
    // highlightField,
    kindChange() {
      this.type_declaration.customs_procedure = null;
      eventBus.$emit("clearTransports");
    },
    setCorrectionReasonJson(reasons) {
      this.hasChanges = true;
      this.fields_been_visited = true;
      reasons.forEach((item) => {
        switch (item.section) {
        case 1:
          this.type_declaration.correction_reason_json.basis_reason =
              item.value;
          break;
        case 2:
          this.type_declaration.correction_reason_json.stage_reason =
              item.value;
          break;
        case 3:
          this.type_declaration.correction_reason_json.quantity_and_weight_reason =
              item.value;
          break;
        case 4:
          this.type_declaration.correction_reason_json.origin_country_reason =
              item.value;
          break;
        case 5:
          this.type_declaration.correction_reason_json.tn_ved_reason =
              item.value;
          break;
        case 6:
          this.type_declaration.correction_reason_json.customs_cost_reason =
              item.value;
          break;
        case 7:
          this.type_declaration.correction_reason_json.payments_reason =
              item.value;
          break;
        case 8:
          this.type_declaration.correction_reason_json.other_reason =
              item.value;
          break;
        }
      });
      if (this.copyInOtherWares) {
        this.copyToAll('correction_reason_json', this.type_declaration.correction_reason_json)
      }
    },
    setFields() {
      const { type_declaration } = this.selected || {};
      const { registration_details } = this.selected || {};
      for (let key in type_declaration) {
        if (key in this.type_declaration) {
          this.type_declaration[key] = type_declaration[key];
        }
      }
      for (let key in registration_details) {
        if (key in this.registration_details) {
          this.registration_details[key] = registration_details[key];
        }
      }
      this.setHasChangesFalse();
    },
    isKindOrProcedureChanged() {
      return (
        this.type_declaration.customs_procedure !==
          this.selected.type_declaration.customs_procedure ||
        this.type_declaration.declaration_kind_code !==
          this.selected.type_declaration.declaration_kind_code
      );
    },
    clearAllPayments() {
      this.$store
        .dispatch("kdt/clearPayments")
        .then(() => this.$info("Очищены платежи"))
        .catch(() => this.$error("Не удалось очистить платежи"));
    },
    async uploadData() {
      const type_declaration = this.convertEmptyStringsToNull(
        this.type_declaration
      );
      const registration_details = this.convertEmptyStringsToNull(
        this.registration_details
      );

      const kindOrProcedureChanged = this.isKindOrProcedureChanged();

      const typeDeclarationResponse = await this.$store.dispatch(
        "kdt/uploadBlockData",
        {
          name: "type_declaration",
          value: type_declaration,
        }
      );

      await this.$store.dispatch("kdt/uploadBlockData", {
        name: "registration_details",
        value: registration_details,
      });

      if (kindOrProcedureChanged) {
        this.clearAllPayments();
      }

      this.disabled = true;
      return Promise.resolve(typeDeclarationResponse);
    },
    suggestRecalc() {
      if (
        this.type_declaration.declaration_feature !== "ВДТ" ||
        this.type_declaration.declaration_feature_date
      )
        this.showSuggestion = true;
    },
  },
};
</script>
