<template>
  <div
    :id="block_id"
    v-click-outside="triggerOnFocus"
    class="document__row pt-1"
    :class="{ 'blocked-box': !checkNumbersInRange }"
    :draggable="disabledView ? false : draggable"
    @dragstart="startDrag($event, document)"
    @mouseup="mouseUp"
  >
    <div class="document__select">
      <v-checkbox
        tabindex="-1"
        dense
        class="pa-0 ml-1"
        hide-details
        color="#5CB7B1"
        :value="selectedDocs.includes(item.id)"
        :disabled="document.bd_44"
        @change="updateSelected"
      />
    </div>

    <div class="document__sort">
      <div
        class="allow-drag"
        @mousedown="mouseDown($event)"
      >
        {{ document.sort_index }}
      </div>
    </div>

    <div class="document__code">
      <v-text-field
        v-show="customFieldCheckerNull(doc_types, document.doc_code, 'code')"
        ref="doc_code"
        v-model="document.doc_code"
        v-mask="'XXXXX'"
        outlined
        :label="index === 0 ? 'Код' : ''"
        dense
        hide-details="auto"
        :background-color="disabledFieldIfBd44 ? 'grey lighten-2': 'white'"
        :disabled="disabledFieldIfBd44"
      />
      <v-tooltip
        bottom
        max-width="300px"
        :disabled="!document.doc_code || hideTooltips"
      >
        <template #activator="{ on }">
          <v-autocomplete
            v-show="!customFieldCheckerNull(doc_types, document.doc_code, 'code')"
            ref="doc_code__custom"
            v-model="document.doc_code"
            auto-select-first
            :label="index === 0 ? 'Код' : ''"
            :items="doc_types"
            item-text="code"
            :filter="filterBySearchField"
            item-value="code"
            outlined
            dense
            hide-details="auto"
            :background-color="disabledFieldIfBd44 ? 'grey lighten-2': 'white'"
            :disabled="disabledFieldIfBd44"
            v-on="on"
          />
        </template>
        <span>{{ docName }}</span>
      </v-tooltip>

      <v-menu offset-x>
        <template #activator="{ on }">
          <v-btn
            tabindex="-1"
            min-width="36px"
            max-width="36px"
            color="#5CB7B1"
            class="ml-1 elevation-0"
            :disabled="disabledFieldIfBd44"
            v-on="on"
          >
            <v-icon>
              mdi-format-list-bulleted
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            tag="button"
            @click="showDocuments"
          >
            Показать справочник
          </v-list-item>
          <v-list-item
            v-if="!customFieldCheckerNull(doc_types, document.doc_code, 'code')"
            tag="button"
            @click="document.doc_code = ''"
          >
            Произвольное значение
          </v-list-item>
          <v-list-item
            v-else
            tag="button"
            @click="document.doc_code = null"
          >
            Значение из справочника
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <div class="document__type">
      <v-autocomplete
        v-model="document.present_type_code"
        auto-select-first
        outlined
        :label="index === 0 ? 'Признак' : ''"
        dense
        hide-details="auto"
        item-text="text"
        item-value="code"
        :items="presentTypes"
        :background-color="disabledView ? 'grey lighten-2' : 'white'"
        :disabled="disabledView"
        @change="onPresentTypeCodeChange"
      />
    </div>
    <div class="document__number">
      <v-text-field
        v-show="document.doc_code !== '03031'"
        ref="doc_number"
        v-model="document.doc_number"
        outlined
        :label="index === 0 ? 'Номер документа' : ''"
        dense
        hide-details="auto"
        :background-color="disabledFieldIfBd44 ? 'grey lighten-2': 'white'"
        :disabled="disabledFieldIfBd44"
      />
      <v-text-field
        v-show="document.doc_code === '03031'"
        ref="doc_number__custom"
        :disabled="document.deal_no_required || disabledFieldIfBd44"
        :label="index === 0 ? 'Номер документа' : ''"
        :value="document.doc_number"
        outlined
        dense
        hide-details="auto"
        :background-color="disabledFieldIfBd44 ? 'grey lighten-2' : 'white'"
        @input="useDocumentMask"
      />
    </div>

    <div class="document__date">
      <custom-date-picker
        ref="doc_date"
        v-model="document.doc_date"
        :label="index === 0 ? 'Дата документа' : ''"
        :visited.sync="fields_been_visited"
        :has-changes.sync="hasChanges"
        :background-color="disabledFieldIfBd44 ? 'grey lighten-2' : 'white'"
        :disabled="disabledFieldIfBd44"
      />
    </div>
    <div class="document__range">
      <v-text-field
        ref="dt_ware_range"
        v-model="range"
        outlined
        placeholder="Все товары"
        dense
        hide-details="auto"
        :background-color="disabledFieldIfBd44 ? 'grey lighten-2' : 'white'"
        :disabled="disabledFieldIfBd44"
        @change="onRangeChange"
      />
    </div>
    <div
      v-if="disabledView"
      class="document__bd44"
    >
      <v-icon v-if="document.bd_44">
        mdi-database-check
      </v-icon>
    </div>
    <div
      v-show="document.present_type_code === '2'"
      class="document__previous_reg"
    >
      <v-text-field
        v-model="document.previous_reg_number"
        v-mask="'#####/######/#######'"
        outlined
        dense
        label="Рег. номер ДТ, с которым был представлен"
        hide-details="auto"
        :background-color="disabledView ? 'grey lighten-2' : 'white'"
        :disabled="disabledView"
      />
    </div>
    <!--  -->
    <div
      v-show="isCertificate"
      class="document__date"
    >
      <custom-date-picker
        ref="certificate_d_on"
        v-model="document.certificate_d_on"
        label="Дата с"
        :visited.sync="fields_been_visited"
        :has-changes.sync="hasChanges"
        :background-color="disabledView ? 'grey lighten-2' : 'white'"
        :disabled="disabledView"
      />
    </div>
    <div
      v-show="isCertificate"
      class="document__date"
    >
      <custom-date-picker
        ref="certificate_d_off"
        v-model="document.certificate_d_off"
        label="Дата по"
        :visited.sync="fields_been_visited"
        :has-changes.sync="hasChanges"
        :background-color="disabledView ? 'grey lighten-2' : 'white'"
        :disabled="disabledView"
      />
    </div>
    <div
      v-show="document.present_type_code === '3'"
      class="document__date"
    >
      <custom-date-picker
        ref="presented_date"
        v-model="document.presented_date"
        label="Предоставить к"
        :visited.sync="fields_been_visited"
        :has-changes.sync="hasChanges"
        :background-color="disabledView ? 'grey lighten-2' : 'white'"
        :disabled="disabledView"
      />
    </div>
    <div
      v-show="document.keeping_date"
      class="document__date"
    >
      <custom-date-picker
        ref="keeping_date"
        v-model="document.keeping_date"
        label="Срок временного ввоза"
        :visited.sync="fields_been_visited"
        :has-changes.sync="hasChanges"
        :background-color="disabledView ? 'grey lighten-2' : 'white'"
        :disabled="disabledView"
      />
    </div>
    <!--  -->
    <div
      v-show="document.add_code"
      class="document__addition"
    >
      <v-autocomplete
        v-model="document.add_code"
        item-value="code"
        item-text="text"
        :items="nsiPresentAddTypes"
        :filter="filterBySearchField"
        label="Доп. инф."
        :background-color="disabledView ? 'grey lighten-2' : 'white'"
        :disabled="disabledView"
        outlined
        dense
        hide-details
      />
    </div>
    <div
      v-show="document.payment_type"
      class="document__payment"
    >
      <v-autocomplete
        ref="payment_type"
        v-model="document.payment_type"
        auto-select-first
        outlined
        label="Вид платежа"
        dense
        hide-details="auto"
        :background-color="disabledView ? 'grey lighten-2' : 'white'"
        :disabled="disabledView"
        class="mr-1"
        item-text="search"
        item-value="code"
        :items="paymentTypes"
      />
    </div>
    <div
      v-show="document.country_letter"
      class="document__country"
    >
      <v-autocomplete
        v-model="document.country_letter"
        item-text="text"
        item-value="letterCode"
        :filter="filterBySearchField"
        :items="countries"
        label="Cтрана выдачи документа"
        :background-color="disabledView ? 'grey lighten-2' : 'white'"
        :disabled="disabledView"
        outlined
        dense
        hide-details
      />
    </div>
    <div v-if="document.doc_code === '03031'">
      <v-btn
        tabindex="-1"
        text
        small
        :disabled="disabledView"
        @click="setNoRegistration"
      >
        <v-icon
          small
          class="mr-2"
        >
          {{
            document.deal_no_required
              ? "mdi-checkbox-marked"
              : "mdi-checkbox-blank-outline"
          }}
        </v-icon>
        Регистрация сделки не требуется
      </v-btn>
    </div>
    <!-- все что связано с сертификацией -->
    <div
      v-show="certificationCodes.includes(document.doc_code)"
      class="document__certificate__country"
    >
      <v-autocomplete
        ref="certificate_country_letter"
        v-model="document.certificate_country_letter"
        item-text="text"
        item-value="letterCode"
        :filter="filterBySearchField"
        :items="countries"
        label="Код страны сертификата"
        :background-color="disabledView ? 'grey lighten-2' : 'white'"
        :disabled="disabledView"
        outlined
        dense
        hide-details
      />
    </div>
    <div
      v-show="certificationCodes.includes(document.doc_code)"
      class="document__payment__code"
    >
      <v-autocomplete
        ref="payment_code"
        v-model="document.payment_code"
        item-text="search"
        item-value="code"
        :items="certificationPaymentTypes"
        label="Вид платежа"
        hide-details="auto"
        :background-color="disabledView ? 'grey lighten-2' : 'white'"
        :disabled="disabledView"
        outlined
        dense
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { deleteNulls } from "@/helpers/inputs";
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import { convertEmptyStringsToNull } from "@/helpers/objects";
import { filterBySearchFieldMixin } from "@/mixins/catalogs";
import {
  isGoodsRangeValid,
  isCorrectStartEnd,
  trimRange,
} from "@/helpers/inputs";
import { showCatalog } from "@/events/statistics";
import { documentTypeSelected } from "@/events/statistics";
import CustomDatePicker from "@/components/shared/custom-date-picker";
import { highlightField } from "@/helpers/control";
import { DEAL_REGISTRATION_NOT_REQUIRED } from "@/constants";
import highlightFieldsChild from "@/mixins/control/highlight-fields-child";
import { getCountryNameByCode } from "@/helpers/catalogs";
import customFieldCheckerNull from '@/mixins/custom-field-checker'

const CERTIFICATE_CODES = ["01401", "01402", "01403", "01404"];
export default {
  components: {
    CustomDatePicker,
  },
  mixins: [
    blockAutoUpdate,
    filterBySearchFieldMixin,
    highlightFieldsChild,
    customFieldCheckerNull
  ],
  props: {
    index: {
      required: true,
      type: Number,
    },
    item: {
      required: true,
      type: Object,
    },
    draggable: {
      required: true,
      type: Boolean,
    },
    forSingleItem: {
      required: true,
      type: Boolean,
    },
    hideTooltips: {
      required: true,
      type: Boolean,
    },
    selectedDocs: {
      required: true,
      type: Array,
    },
    presentedDocuments: {
      required: true,
      type: Array,
    },
    control: {
      required: true,
      type: Object,
    },
    presentTypes: {
      required: true,
      type: Array,
    },
  },
  data: () => ({
    selected: null,
    mousePressed: false,
    isIntersecting: false,
    parent: null,
    block_id: null,
    menu: false,
    document: {
      deal_no_required: "",
      declaration_id: "",
      doc_code: "",
      doc_date: "",
      doc_number: "",
      id: "",
      present_type_code: "",
      sort_index: "",
      dt_ware_range: "",
      //
      country_letter: "",
      country_name: "",
      add_code: "",
      previous_reg_number: "",
      certificate_d_on: "",
      certificate_d_off: "",
      presented_date: "",
      keeping_date: "",
      payment_type: "",
      payment_code: "",
      certificate_country_letter: "",
      certificate_country_name: "",
      bd_44: false,
      doc_type_bd_44: "",
    },
    range: "",
    noRegistration: true,
    key: "",
    noRegistrationText: DEAL_REGISTRATION_NOT_REQUIRED,
    certificationCodes: [
      "06011",
      "06013",
      "06014",
      "06015",
      "06016",
      "06017",
      "06018",
      "06019",
    ],
  }),
  computed: {
    ...mapGetters({
      doc_types: "catalogs/getNsiTypesOfDocument",
      customsPoints: "catalogs/getNsiCustomsPoints",
      countries: "catalogs/getNsiCountries",
      nsiPresentAddTypes: "catalogs/getNsiPresentAddTypes",
      paymentTypes: "kdt/getPaymentTypes",
      disabledView: "kdt/getVisibility",
      getWaresWhichHaveSortIndex: "kdt/getWaresWhichHaveSortIndex",
    }),
    certificationPaymentTypes() {
      return this.paymentTypes.filter((item) =>
        ["2010", "5010"].includes(item.code)
      );
    },
    arraySortIndexWaresKtd() {
      return this.getWaresWhichHaveSortIndex.map((item) => item.dt_sort_index);
    },
    isCertificate() {
      return CERTIFICATE_CODES.includes(this.document.doc_code);
    },
    docName() {
      const { name } =
        this.doc_types.find((i) => i.code === this.document.doc_code) || {};
      return name || "";
    },
    disabledFieldIfBd44() {
      return !!(this.disabledView && this.docTypeBd44);
    },
    docTypeBd44() {
      if (this.document.doc_type_bd_44 === 2) {
        if (this.document.bd_44) return true;
        else return false
      } return true
    },
    // проверка наличия чисел в диапазоне
    checkNumbersInRange() {
      if (this.item.dt_ware_range) {
        // Проверяем если просто число
        if (
          this.arraySortIndexWaresKtd.includes(Number(this.item.dt_ware_range))
        )
          return true;
        else {
          // Разделяем строку на отдельные диапазоны
          const ranges = this.item.dt_ware_range
            .split(",")
            .map((range) => range.trim());
          // Ищем каждое число из arraySortIndexWaresKtd в диапазонах
          const includedIndex = this.arraySortIndexWaresKtd.filter((num) => {
            return ranges.some((range) => {
              const [min, max] = range.split("-").map(Number);
              return num >= min && num <= max;
            });
          });
          return !!includedIndex.length;
        }
      } else return true;
    },
  },
  watch: {
    "document.doc_code": {
      handler(nv) {
        if (
          (nv === "03031" &&
            !this.checkDocumentMask(this.document.doc_number) &&
            !this.document.deal_no_required) ||
          (nv !== "03031" &&
            this.document.doc_number === DEAL_REGISTRATION_NOT_REQUIRED) //Если был 03031 то очищаем поле в случае "не трубует регистрации"
        ) {
          this.document.doc_number = "";
          this.document.deal_no_required = null;
        }
      },
    },
    control: {
      handler({ path, index }) {
        if (path !== "" && this.index === index - 1) {
          this.$nextTick(() => {
            highlightField.call(this, { path });
          });
        }
      },
      deep: true,
      immediate: true,
    },
    range(nv) {
      this.validateRange(nv.replace(".", ","));
    },
    item: {
      handler(newVal) {
        this.block_id = `kdt-present-document-${this.item.id}`;
        for (let key in newVal) {
          if (key in this.document) {
            this.document[key] = newVal[key];
          }
        }
        this.range = newVal?.dt_ware_range ?? "";
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    documentTypeSelected.subscribe(this.checkAndUpdateDocType);
    const collection = [...document.getElementsByClassName("drop-zone")];
    const [parent] = collection;
    this.parent = parent;
  },
  beforeDestroy() {
    documentTypeSelected.unsubscribe();
  },
  methods: {
    onPresentTypeCodeChange(value) {
      if (value !== "2") {
        this.document.previous_reg_number = null;
      }
      if (value !== "3") {
        this.document.presented_date = null;
      }
      if (!CERTIFICATE_CODES.includes(value)) {
        this.document.certificate_d_on = null;
        this.document.certificate_d_off = null;
      }
    },
    checkDocumentMask(value) {
      const regex = /([0-9]{6}\/[0-9]{6}\/[0-9]{5})/g;
      return new RegExp(regex).test(value);
    },
    useDocumentMask(value) {
      // mask = ######/######/#####
      if (this.document.doc_code === "03031") {
        const symbols = value.split("");
        const errors = symbols.filter(
          (i) => i !== "/" && Number.isNaN(Number.parseInt(i))
        );
        if (errors.length || value.length > 19) {
          const { doc_number } = this.document;
          this.document.doc_number = ""; // Чтобы значение в v-text-field обновилось
          this.$nextTick(() => {
            this.document.doc_number = doc_number;
          });
        } else {
          if (
            value.length > this.document.doc_number?.length &&
            (value.length === 7 || value.length === 14)
          ) {
            const symbols = value.split("").reverse();
            const [end, ...start] = symbols;
            this.document.doc_number = [...start.reverse(), "/", end].join("");
          } else {
            this.document.doc_number = value;
          }
        }
      } else {
        this.document.doc_number = value;
      }
    },
    onRangeChange(value) {
      this.range = trimRange(value);
    },
    addNewDoc() {
      this.$emit("addNewDoc");
    },
    updateSelected() {
      this.$emit("select", this.item.id);
    },
    validateRange(nv) {
      const result = isGoodsRangeValid(nv);
      if (result !== false) {
        this.document.dt_ware_range = result;
        this.range = result;
      } else {
        this.$nextTick(() => {
          this.range = this.document.dt_ware_range;
        });
      }
    },
    startDrag(evt, document) {
      if (this.hasChanges && this.fields_been_visited) {
        return this.$snackbar({
          text: "Сохраните изменения перед сортировкой.",
          color: "orange",
          top: false,
          right: false,
        });
      }
      evt.dataTransfer.dropEffect = "move";
      evt.dataTransfer.effectAllowed = "move";
      evt.dataTransfer.setData("document", document.id);
    },
    mouseUp() {
      this.$emit("update:draggable", false);
    },
    mouseDown(evt) {
      // Перетаскивание только в режиме просмотра всех товаров
      if (
        [...evt.target.classList].includes("allow-drag") &&
        !this.forSingleItem
      ) {
        this.$emit("update:draggable", true);
      }
    },
    deleteNulls,
    deleteDocument() {
      const { id } = this.item;
      const index = this.index;
      this.$emit("delete", {
        id,
        index,
      });
    },
    uploadData() {
      if (!isCorrectStartEnd(this.document.dt_ware_range)) {
        this.processing = false; //  Если вышли из блока когда не верный диапазон то надо разрешить отправку повторно
        return this.$snackbar({
          text: "Неверное начало и конец диапазона товаров",
          color: "red",
          top: false,
          right: false,
        });
      }
      const index = this.index;
      const payload = convertEmptyStringsToNull({
        ...this.document,
        country_name: getCountryNameByCode(
          this.countries,
          this.document.country_letter
        ),
        certificate_country_name: getCountryNameByCode(
          this.countries,
          this.document.certificate_country_letter
        ),
      });
      return this.$store.dispatch("kdt/updateDocument", {
        index,
        payload,
      });
    },
    showDocuments() {
      showCatalog.trigger({
        type: "doctypes",
        id: this.document.id,
        search: this.document.doc_code,
      });
    },
    checkAndUpdateDocType({ id, code }) {
      if (this.document.id === id) {
        this.document.doc_code = code;
        this.fieldsBeenVisitedByUser();
      }
    },
    setNoRegistration() {
      const newValue = !this.document.deal_no_required;
      this.document.deal_no_required = newValue;
      if (newValue === true) {
        this.document.doc_number = DEAL_REGISTRATION_NOT_REQUIRED;
      } else {
        this.document.doc_number = null;
      }
      this.document.doc_date = null;
      this.fieldsBeenVisitedByUser();
      this.hasChanges = true;
    },
  },
};
</script>
<style scoped>
.document__row {
  display: inline-flex;
  align-items: center;
}
.document__select {
  display: flex;
  justify-content: center;
  align-items: center;
}
.document__sort {
  width: 40px !important;
  margin-right: 10px;
  flex-grow: 0;
  flex-shrink: 0;
}

.document__code {
  margin-right: 10px;
  width: 140px !important;
  display: flex;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
}
.document__sign {
  width: 160px !important;
  flex-grow: 0;
  flex-shrink: 0;
}
.document__number {
  width: 280px !important;
  margin-right: 10px;
  flex-grow: 0;
  flex-shrink: 0;
}
.document__date {
  width: 160px !important;
  margin-right: 10px;
  flex-grow: 0;
  flex-shrink: 0;
}
.document__range {
  width: 200px !important;
  margin-right: 10px;
  flex-grow: 0;
  flex-shrink: 0;
}
.document__addition {
  width: 80px !important;
  margin-right: 10px;
  flex-grow: 0;
  flex-shrink: 0;
}
.document__type {
  max-width: 80px;
  margin-right: 10px;
  flex-grow: 0;
  flex-shrink: 0;
}
.document__country {
  max-width: 150px;
  margin-right: 10px;
  flex-grow: 0;
  flex-shrink: 0;
}
.document__payment {
  max-width: 100px;
  margin-right: 10px;
  flex-grow: 0;
  flex-shrink: 0;
}
.document__previous_reg {
  max-width: 200px;
  margin-right: 10px;
  flex-grow: 0;
  flex-shrink: 0;
}
.document__certificate__country {
  max-width: 180px;
  margin-right: 10px;
  flex-grow: 0;
  flex-shrink: 0;
}
.document__payment__code {
  max-width: 130px;
  margin-right: 10px;
  flex-grow: 0;
  flex-shrink: 0;
}
.h-full {
  height: 100%;
}

.document-row:last-child {
  padding: 0 10px 4px 0 !important;
}
.allow-drag {
  cursor: pointer !important;
}
</style>
