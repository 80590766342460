<template>
  <div
    :id="block_id"
    v-click-outside="triggerOnFocus"
    class="d-flex px-1 py-1"
  >
    <div class="header__code">
      <v-text-field
        ref="number"
        v-model="transport_item.number"
        outlined
        dense
        hide-details="auto"
        class="mr-0"
        background-color="white"
        @keypress.enter="showVehiclesCatalog"
      />
    </div>
    <div 
      v-if="!xsdUpdate" 
      class="header__vin"
    >
      <v-text-field
        ref="vin"
        v-model="transport_item.vin"
        v-mask="customVinMask"
        background-color="white"
        dense
        outlined
        hide-details="true"
        @change="readyToUpdate"
      />
    </div>
    <div
      v-if="!xsdUpdate"
      class="header__currency"
    >
      <v-autocomplete
        ref="country_letter"
        v-model="transport_item.country_letter"
        auto-select-first
        outlined
        dense
        hide-details="auto"
        background-color="white"
        item-text="letterCode"
        item-value="letterCode"
        :items="countries"
        :filter="filterBySearchField"
        @change="readyToUpdate"
      />
    </div>
    <div
      v-if="xsdUpdate"
      class="header__type"
    >
      <v-autocomplete
        v-model="transport_item.vehicle_type"
        auto-select-first
        background-color="white"
        dense
        outlined
        hide-details="true"
        item-value="code"
        item-text="text"
        :items="vehicleTypes"
        :filter="filterBySearchField"
        @change="readyToUpdate"
      />
    </div>
    <div
      ref="graph_18"
      class="header__code_sp my-auto"
    >
      <v-checkbox

        v-model="transport_item.graph_18"
        dense
        hide-details
        color="#5CB7B1"
        @change="readyToUpdate"
      />
    </div>
    <div
      ref="graph_21"
      class="header__code_sp my-auto"
    >
      <v-checkbox

        v-model="transport_item.graph_21"
        dense
        hide-details
        color="#5CB7B1"
        @change="readyToUpdate"
      />
    </div>

    <div class="header__action">
      <v-btn
        tabindex="-1"
        max-width="36px"
        min-width="36px"
        color="transparent"
        class="elevation-0 document-item-delete"
        @click="deleteTransport"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
      <v-btn
        v-if="index === amount - 1"
        class="document-add elevation-0"
        color="transparent"
        min-width="36px"
        max-width="36px"
        @click="addTransport"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import blockAutoUpdate from "@/mixins/block-auto-update.mixin"
import { filterBySearchFieldMixin } from "@/mixins/catalogs"
import {getCountryNameByCode} from "@/helpers/catalogs"
import {catalogValueSelected, showCatalog} from "@/events/statistics";
import {mapGetters} from "vuex";
import {customVinMask} from "@/helpers/inputs";
import {
  highlightTransportItemField,
} from "@/helpers/control";

export default {
  mixins: [blockAutoUpdate, filterBySearchFieldMixin],
  props: {
    control: {
      required: true,
      type: Object
    },
    item: {
      required: true,
      type: Object
    },
    index: {
      required: true,
      type: Number
    },
    amount: {
      required: true,
      type: Number
    },
    base: {
      required: true,
      type: Object
    },
    shell: {
      required: true,
      type: Array
    },
    all: {
      required: true,
      type: Array
    },
    // справочники
    countries: {
      required: true,
      type: Array
    },
    vehicleTypes: {
      required: true,
      type: Array,
    },
    xsdUpdate: {
      required: true,
      type: Boolean,
    }
  },
  data(){
    return {
      block_id:null,
      transport_item: {
        number: "",
        country_letter: "",
        country_name: "",
        vin: "",
        graph_18: "",
        graph_21: "",
        vehicle_type: ""
      }
    }
  },
  computed:{
    customVinMask() {
      return customVinMask
    },
    ...mapGetters({
      user:"auth/getUser",
    }),
    divisionId() {
      return this.user?.user?.activeDivisionId;
    }
  },
  watch: {
    item: {
      handler(newVal) {
        this.block_id = `kdt-transport-${this.index + 1}`
        for (let key in newVal) {
          if (key in this.transport_item) {
            this.transport_item[key] = newVal[key];
          }
        }
      },
      immediate: true,
      deep: true,
    },
    control: {
      handler({path}) {
        highlightTransportItemField.call(this, path)
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    catalogValueSelected.subscribe(this.updatesResolver, [
      'transport/number',
    ])
  },
  beforeDestroy() {
    catalogValueSelected.unsubscribe()
  },
  methods: {
    saveToCatalog() {
      const country_name = getCountryNameByCode(this.countries, this.transport_item.country_letter)
      const payload = {
        id: null,
        division_id: this.divisionId,
        number: this.transport_item.number,
        country_letter: this.transport_item.country_letter,
        vehicle_type: this.transport_item.vehicle_type,
        transport_mark_name: null,
        vin: this.transport_item.vin,
        doc_number: null,
        model_name: null,
        transport_mark_code: null,
        country_name,
      }
      this.$store.dispatch("catalogs/saveVehicle", payload)
    },
    updatesResolver({id, field, code, item}) {
      if (id === this.index && code && field) {
        if (field === 'transport/number') {
          this.updateTransport(item)
        } else {
          const [, key] = field.split('/')
          this.transport_item[key] = code
        }
        this.readyToUpdate()
      }
    },
    updateTransport(transport) {
      const { number, country_letter, vin, vehicle_type } = transport
      this.transport_item = {
        ...this.transport_item,
        number,
        country_letter,
        vin,
        vehicle_type
      }
    },
    showVehiclesCatalog() {
      showCatalog.trigger({
        type: "vehicles",
        id: this.index,
        search: this.transport_item.number,
        field: "transport/number"
      });
    },
    readyToUpdate() {
      this.hasChanges = true;
      this.fields_been_visited = true;
    },
    async addTransport(){
      await this.uploadData()
      this.$emit('addTransport')
    },
    deleteTransport(){
      this.$emit('deleteTransport', this.index)
    },
    uploadData() {
      this.transport_item = {
        ...this.transport_item,
        country_name: getCountryNameByCode(this.countries, this.transport_item.country_letter) || null,
      }
      const transports_json = [...this.all]
      transports_json[this.index] = {
        ...this.transport_item
      }

      const about_transport = this.convertEmptyStringsToNull({
        ...this.base,
        transports_json: [...transports_json],
        destination_customs_json: this.shell
      })

      return this.$store.dispatch("kdt/uploadBlockData", {
        name: "about_transport",
        value: about_transport,
      }).then((res) => {
        this.saveToCatalog()
        return res
      })
    }
  }
}
</script>

<style scoped>
.header__code{
  width: 120px !important;
  margin: 0 10px 0 0;
}
.header__vin{
  width: 190px !important;
  margin: 0 10px 0 0;
}
.header__currency{
    width: 120px !important;
    margin: 0 10px 0 0;
}
.header__code_sp{
   width: 60px !important;
   margin: 0 10px 0 0;
}
.header__type{
  width: 190px !important;
  margin: 0 10px 0 0;
}
/* .header__action{
  width: 160px !important;
  padding: 0 10px 0 0;
} */
</style>
