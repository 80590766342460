<template>
  <v-overlay :value="show">
    <v-card
      v-click-outside="close"
      color="#EDEDED"
      width="700"
      light
    >
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-toolbar color="#333">
          <div class="text-md-body-1 white--text">
            Редактирование кода изменений
          </div>
          <v-spacer />
          <v-btn
            color="error"
            small
            @click="close"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pa-6">
          <v-row
            v-for="item in changeList"
            :key="item.section"
          >
            <v-col
              cols="4"
              class="text-subtitle-1 py-0"
            >
              {{ item.text }}
            </v-col>
            <v-col
              cols="8"
              class="py-0"
            >
              <v-select
                v-model="item.value"
                :items="correctionTypesFiltered(item.section)"
                :rules="rules"
                item-text="search"
                item-value="code"
                outlined
                dense
                background-color="white"
                :menu-props="{ bottom: true, offsetY: true }"
                @click.stop
              >
                <template #item="{ item }">
                  <v-tooltip
                    bottom
                    max-width="400px"
                  >
                    <template #activator="{ on, attrs }">
                      <v-layout
                        wrap
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            {{
                              keywordsText(item.search)
                            }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-layout>
                    </template>
                    <span>{{ item.search }}</span>
                  </v-tooltip>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-card-actions class="px-0 mt-5">
            <v-checkbox
              v-if="isKdtWares"
              v-model="copyInOtherWares"
              label="Применить ко всем товарам"
              hide-details
              tabindex="-1"
              dense
              color="#5CB7B1"
            />
            <v-spacer />
            <v-btn
              class="ml-2 px-4 green white--text"
              :disabled="!valid"
              @click.stop="save"
            >
              Сохранить
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-form>
    </v-card>
  </v-overlay>
</template>

<script>
const defaultState = [
  {
    value: "",
    section: 1,
    text: "1. Этап",
  },
  {
    value: "",
    section: 2,
    text: "2. Основание",
  },
  {
    value: "",
    section: 3,
    text: "3. Количество/ вес",
  },
  {
    value: "",
    section: 4,
    text: "4. Страна происхождения",
  },
  {
    value: "",
    section: 5,
    text: "5. Код  ТН ВЭД",
  },
  {
    value: "",
    section: 6,
    text: "6. Таможенная стоимость",
  },
  {
    value: "",
    section: 7,
    text: "7. Платежи",
  },
  {
    value: "",
    section: 8,
    text: "8. Иное",
  },
];

import { mapGetters } from "vuex";
import cloneDeep from "lodash.clonedeep";
import closeOnEscape from "@/mixins/close-on-escape.mixin"

export default {
  mixins:[closeOnEscape],
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    correctionReason: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    valid: true,
    copyInOtherWares: false,
    rules: [(value) => !!value || "Поле должно быть заполнено"],
    changeList: [],
  }),
  computed: {
    ...mapGetters({
      requests: "kdt/getRequests",
      selected: "kdt/getSelected",
      selected_wares: "kdt/getSelectedWares",
      nsiCorrectionTypes: "catalogs/getNsiCorrectionTypes",
    }),
    // есть ли товары в КДТ (признак sort_index)
    isKdtWares() {
      const filtered = this.selected_wares.filter(i => i.sort_index)
      return !!filtered.length
    },
  },
  watch: {
    selected: {
      handler() {
        this.setChangeList();
      },
      deep: true,
    },
  },
  methods: {
    setChangeList() {
      let clone = cloneDeep(defaultState);
      if (defaultState) {
        clone.forEach((item) => {
          switch (item.section) {
          case 1:
            item.value = this.correctionReason.basis_reason;
            break;
          case 2:
            item.value = this.correctionReason.stage_reason;
            break;
          case 3:
            item.value = this.correctionReason.quantity_and_weight_reason;
            break;
          case 4:
            item.value = this.correctionReason.origin_country_reason;
            break;
          case 5:
            item.value = this.correctionReason.tn_ved_reason;
            break;
          case 6:
            item.value = this.correctionReason.customs_cost_reason;
            break;
          case 7:
            item.value = this.correctionReason.payments_reason;
            break;
          case 8:
            item.value = this.correctionReason.other_reason;
            break;
          }
        });
        this.changeList = clone;
      }
    },
    correctionTypesFiltered(section) {
      return this.nsiCorrectionTypes.filter((type) => type.section === section);
    },
    keywordsText(text) {
      if (text.length < 60) return text;
      else return `${text.slice(0, 60)}...`;
    },
    save() {
      if (this.$refs.form.validate()) {
        this.$emit("update:copyInOtherWares", this.copyInOtherWares);
        this.$emit("setCorrectionReasonJson", this.changeList);
        this.close();
      }
    },
    clear() {
      // убрал очищение, т.к. затираются данные уже сохраненные в БД
      // возможно нужно будет подправить логику
      // this.changeList = cloneDeep(defaultState)
      this.$refs.form.resetValidation();
    },
    close(event) {
      const isClickInside = this.$el.contains(event?.target);
      if(isClickInside || !event) {
        this.clear();
        this.$emit("update:show", false);
      }
    },
  },
};
</script>
